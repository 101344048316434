window.generic || {};
var site = site || {};

site.stickyOffer = site.stickyOffer || {};

site.stickyOffer = {
  bps: '',
  bp: '',
  isDesktop: '',
  closeTab: '',
  offerClosed: 1,
  offerCount: 0,
  isSpp: 0,
  $container: $(),
  $offerItems: $(),
  $offerItem: $(),
  $parent: $(),
  $trigger: $(),
  $offerLink: $(),
  $offerClose: $(),
  $offerAmount: $(),
  $spp: $(),
  $sppCartBtn: $(),
  $sppSkuBaseId: '',
  breakpoints: function () {
    var self = this;

    self.bps = Unison.fetch.all();
    self.bp = Unison.fetch.now();
    self.isDesktop = parseInt(self.bp.width, 10) >= parseInt(self.bps.medium, 10);
  },
  carousel: function () {
    var self = this;

    self.offerCount = self.$offerItem.length;
    self.$offerAmount.text(self.offerCount);

    self.$offerItems.on('init', function (event, slick, currentSlide, nextSlide) {
      if (!self.closeTab && _.isUndefined(generic.cookie('sticky_offer'))) {
        // show only once for new users
        generic.cookie('sticky_offer', 1);
      }
    });

    self.$offerItems.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      responsive: [
        {
          breakpoint: parseInt(self.bps.medium, 10),
          settings: {
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    });

    self.addEvents();
  },
  addEvents: function () {
    var self = this;

    // self.$trigger.addClass('sticky__offer__mobile');
    self.$trigger.once().bind('click', function (event) {
      event.preventDefault();
      if (self.isDesktop) {
        return;
      }
      if (self.offerClosed) {
        $(this).addClass('sticky__offer__mobile');
        self.$trigger.removeClass('sticky__offer__mobile--close');
        self.$offerItems.slick('setPosition');
        self.offerClosed = 0;
      }
    });

    self.$offerLink.once().bind('click', function (event) {
      event.preventDefault();
      var offerCode = $(this).data('offercode');
      var url = $(this).attr('href');

      if (offerCode.length) {
        // TODO: apply offercode
      } else if (url.length) {
        window.location.href = url;
      }
    });

    self.$offerClose.once().bind('click', function (event) {
      event.preventDefault();
      if (!self.offerClosed) {
        self.$trigger.removeClass('sticky__offer__mobile');
        setTimeout(function () {
          self.offerClosed = 1;
          self.$trigger.addClass('sticky__offer__mobile--close');
        }, 10);
      }
    });

    self.$addToBag.once().bind('click', function (event) {
      event.preventDefault();
      if (!self.isSpp) {
        return;
      }
      // trigger spp cart
      self.$sppCartBtn.trigger('click');
    });

    Unison.on('change', function (bp) {
      self.bp = Unison.fetch.now();
      self.isDesktop = parseInt(self.bp.width, 10) >= parseInt(self.bps.medium, 10);
      // update events
    });
  },
  getDom: function (context, offerContainer) {
    var self = this;

    self.$container = $(offerContainer);
    self.$offerItems = $('.js-sticky__offer__items', self.$container);
    self.$offerItem = $('.js-sticky__offer', self.$container);
    self.$parent = self.$container.closest('.js-sticky__footer__offer');
    self.$trigger = $('.js-sticky__footer__offer', context);
    self.$offerLink = $('.js-sticky__offer__link', self.$container);
    self.$offerClose = $('.js-sticky__offer__close', self.$container);
    self.$offerAmount = $('.js-sticky__offer__count', self.$container);
    self.$spp = $('.page-product', context);
    // due to how bumble pro has two versions of add-to-bag. We chose the
    // visible one.
    self.$sppCartBtn = $('.product-full__add-to-bag .js-add-to-cart:visible', self.$spp);
    self.$addToBag = $('.js-sticky__cart__cta__link', context);

    // vars
    self.breakpoints();
    self.closeTab = self.$container.data('close');
    self.isSpp = self.$spp.length;
    self.$sppSkuBaseId = self.$sppCartBtn.data('sku-base-id');

    self.carousel();
  },
  init: function (context, offerContainer) {
    var self = this;

    self.getDom(context, offerContainer);
  }
};

site.stickyButton = {
  update: function (params) {
    var $spp = $('.js-product.product-full').closest('.page-product');
    var $addToBag = $('.js-sticky__cart__cta__link', $spp);
    var $sppCartBtn = $('.js-product-full-add-to-bag .js-add-to-cart:visible', $spp);
    var $waitlist = $('.js-sticky-add-to-waitlist', $spp);
    var $stickyParent = $addToBag.closest('.js-sticky__footer__offer');

    $addToBag.once().bind('click', function (event) {
      event.preventDefault();
      // trigger spp cart
      $sppCartBtn.trigger('click');
    });
    if (params.label !== undefined) {
      $addToBag.text(params.label);
    }
    // update button
    if (!params.isShoppable) {
      $stickyParent.addClass('sticky--disabled');
      if (params.status === 3) {
        $addToBag.addClass('hidden');
        $waitlist.removeClass('hidden');
        $stickyParent.removeClass('sticky--disabled');
      }
    } else {
      $stickyParent.removeClass('sticky--disabled');
    }
  }
};

(function ($) {
  Drupal.behaviors.stickyOfferFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-sticky__offer__formatter', context);

      $template.each(function (index, el) {
        site.stickyOffer.init(context, this);
      });
    }
  };
})(jQuery);
